req = window.superagent;

# API 接続設定
fqdn = 'api.fx-parts.net'
resource = 'fx'
version = '1'
resourceUrl = "//#{fqdn}/#{resource}/#{version}/"

# 埋め込み情報
idAttribute = 'data-fxnavi-id'
typeAttribute = 'data-fxnavi-type'

# データ埋め込み
document.addEventListener 'DOMContentLoaded', () ->
  # 必要なトレーダーの一覧を洗い出す
  allIdList = []
  targets = document.querySelectorAll "[#{idAttribute}]"
  _.each targets, (target) ->
    id = target.getAttribute idAttribute
    allIdList.push id

  # 重複を取り除く
  idList = allIdList.filter (value, index, self) ->
    return self.indexOf(value) == index

  # 必要な情報をサーバーから取得する
  _.each idList, (id) ->
    req.get("#{resourceUrl}trader/?id=#{id}").end(
      (res) ->
        embedData(res.body)
    )

# Ajax で取得したデータを埋め込む
embedData = (data) ->
  # 該当のトレーダーの情報を埋める必要がある対象を洗い出す
  targets = document.querySelectorAll "[#{idAttribute}='#{data.id}']"
  # 設定してある情報を埋め込む
  _.each targets, (target) ->
    type = target.getAttribute typeAttribute
    target.textContent = data[type] ? ''
